import React, { useEffect, useState } from "react";
import weedIcon from "../../assets/weedIcon.png";
import cigaretteIcon from "../../assets/cigaretteIcon.png";
import coffeeIcon from "../../assets/coffee.png";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./style.module.scss";

const Main = () => {
  const [cigarettesCount, setCigarettesCount] = useState(0);
  const [jointsCount, setJointsCount] = useState(0);
  const [coffeeCount, setCoffeeCount] = useState(0);

  useEffect(() => {
    // Load counts from local storage on component mount
    const storedCigarettesCount =
      parseInt(localStorage.getItem("cigarettesCount")) || 0;
    const storedJointsCount =
      parseInt(localStorage.getItem("jointsCount")) || 0;

    const storedCoffeeCount =
      parseInt(localStorage.getItem("coffeeCount")) || 0;
    setCoffeeCount(storedCoffeeCount);
    setCigarettesCount(storedCigarettesCount);
    setJointsCount(storedJointsCount);
  }, []);

  const incrementCigarettesCount = () => {
    const newCount = cigarettesCount + 1;
    setCigarettesCount(newCount);
    localStorage.setItem("cigarettesCount", newCount);
  };

  const incrementJointsCount = () => {
    const newCount = jointsCount + 1;
    setJointsCount(newCount);
    localStorage.setItem("jointsCount", newCount);
  };

  const incrementCoffeeCount = () => {
    const newCount = coffeeCount + 1;
    setCoffeeCount(newCount);
    localStorage.setItem("coffeeCount", newCount);
  };

  const clearLocalStorage = () => {
    localStorage.clear();
    setCigarettesCount(0);
    setJointsCount(0);
    setCoffeeCount(0)
  };

  return (
    <div id='all-app' className={styles.container}>
      <div className={styles.title}>מעקב עישון יומי</div>
      <div className={styles.btns}>
        <Button
          variant="contained"
          color="error"
          onClick={incrementCigarettesCount}
        >
          <img className={styles.img} src={cigaretteIcon} alt="" />
        </Button>
        <Button variant="contained" onClick={incrementCoffeeCount}>
          <img className={styles.img} src={coffeeIcon} alt="" />
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={incrementJointsCount}
        >
          <img className={styles.img} src={weedIcon} alt="" />
        </Button>
      </div>
      <div>
        <h1>{`${cigarettesCount} : סיגריות`}</h1>
        <h1>{`${coffeeCount} : כוסות קפה`}</h1>
        <h1>{`${jointsCount} : ג'ויינטים`}</h1>
      </div>

      <Button
        onClick={clearLocalStorage}
        style={{ color: "white", fontFamily: "RubikGemstones" }}
        size="large"
        variant="outlined"
        startIcon={<DeleteIcon />}
      >
        איפוס נתונים
      </Button>
    </div>
  );
};

export default Main;
